<template>
	<div class="root">
		<div v-if="!login || privacyPolicy || userAgreement">
			<router-view></router-view>
		</div>
		<el-container v-else style="height: 100%;">
			<!-- 侧边栏 -->
			<el-aside width="200px">
				<div class="header-logo">
					<img class="header-logo-img" src="@/asset/logo.png" />
					GRAND SING ADMIN
				</div>
				<el-menu class="aside-menu" :default-active="activeIndex" @select="handleMenuSelect"
					background-color="#123058" text-color="#fff" active-text-color="#409eff" router
					:collapse="collapse">
					<el-menu-item index="/" id="Dashboard">
						<template slot="title">
							<i class="el-icon-data-analysis"></i>
							<span>{{ $t('Dashboard') }}</span>
						</template>
					</el-menu-item>

					<el-submenu v-for="(item,index) in menuList" :key="index" :index="item.code">
						<template slot="title">
							<i :class="item.icon"></i>
							<span>{{ $i18n.locale == "zh" ? item.name : item.enName }}</span>
						</template>
						<div v-for="(sub,idx) in item.children">
							<div v-for="(son, i) in sub.children" :key="i"
								v-if="sub.state==1 && son.code.endsWith(':VIEW')">
								<el-menu-item :index="sub.route">
									{{ $i18n.locale == "zh" ? sub.name : sub.enName }}
								</el-menu-item>
							</div>
						</div>
					</el-submenu>
				</el-menu>
			</el-aside>
			<!-- 内容区域 -->
			<el-container>
				<el-header class="header">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item>Grand Sing Engineering Limited</el-breadcrumb-item>
					</el-breadcrumb>
					<div class="login-user">
						<div class="lang" @click="changLang">
							<span v-if="$i18n.locale == 'zh'">EN</span>
							<span v-else>中</span>
						</div>
						{{user.nickname}}
						<el-divider direction="vertical"></el-divider>
						<el-dropdown v-if="user">
							<span class="el-dropdown-link">
								<el-avatar :size="35">{{user.nickname.substring(0,1)}}</el-avatar>
							</span>
							<el-dropdown-menu slot="dropdown">
								<template>
									<el-dropdown-item>
										<template>
											<el-button type="text" @click="logout">{{ $t('Logout') }}</el-button>
										</template>
									</el-dropdown-item>
								</template>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</el-header>
				<el-main style="overflow: scroll;">
					<!-- 路由视图 -->
					<router-view></router-view>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
	import {
		getuserpermission,
		getCurrentUser,
	} from '@/http/user'
	import {
		logout
	} from '@/http/base'
	import {
		getBaseurl
	} from '@/http/file'

	export default {
		data() {
			return {
				baseurl: '',
				EnglishName: '',
				menuList: [],
				login: false,
				privacyPolicy:false,
				userAgreement:false,
				activeIndex: '0',
				collapse: false,
				breadcrumbTwo: '',
				breadcrumbThree: '',
				breadcrumbShow: false,
				user: {
					nickname: '未登錄',
				},
			}
		},
		methods: {
			logout() {
				this.$confirm(this.$i18n.tc('LoginOutWarning'), this.$i18n.tc('prompt')).then(() => {
					logout(null).then(res => {
						this.$message({
							type: 'success',
							message: res.msg
						});
						localStorage.removeItem('token');
						this.$router.push({
							path: '/login'
						});
						window.location.reload()
					})
				}).catch(() => {});
			},
			handleMenuSelect(e) {
				sessionStorage.setItem('route',e)
			},
			changLang() {
				if (!sessionStorage.getItem('languge')) {
					sessionStorage.setItem('languge','zh')
				}
				sessionStorage.setItem('languge',sessionStorage.getItem('languge') == "zh" ? 'en' : 'zh')
				this.$i18n.locale = sessionStorage.getItem('languge')
			},
			//获取当前用户
			getPermissions() {
				getuserpermission(null).then(res => {
					this.menuList = res.data;
				})
			},
			getCurrentUser() {
				getCurrentUser().then(res => {
					if (res.code == 200) {
						this.user = res.data;
					}
				})
			},
			getBaseurl() {
				getBaseurl().then(res => {
					if (res.code == 200) {
						this.baseurl = res.data
					}
				})
			},
		},
		watch: {
			$route(to, from) {
				if (to.fullPath == '/' && from.fullPath == '/login') {
					sessionStorage.setItem('route','/')
					window.location.reload()
				}
				if(to.fullPath == '/resource/privacyPolicy'){
					this.privacyPolicy = true
				}
				if(to.fullPath == '/resource/userAgreement'){
					this.userAgreement = true
				}
			}
		},
		created() {
			if (localStorage.getItem('token')) {
				if(performance.navigation.type == 1){
					this.activeIndex = sessionStorage.getItem('route')
				}
				this.login = true;
				this.getBaseurl();
				this.getCurrentUser();
				this.getPermissions();
			}
		},
	}
</script>

<style lang="less" scoped>
	::-webkit-scrollbar {
		width: 0;
		/* 隐藏滚动条宽度 */
	}

	.root {
		height: 100vh;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e8e8e8;
		background-color: #fff;
		text-align: center;
	}

	.el-aside {
		display: flex;
		height: 100%;
		flex-direction: column;
	}

	
	/deep/.el-submenu__title{
		padding-left: 10px !important;
	}
	/deep/.el-menu-item{
		padding-left: 35px !important;
	}

	#Dashboard{
		padding-left: 10px !important;
	}

	.aside-menu {
		flex: 1;
		overflow-y: scroll;
	}

	.header-logo {
		display: flex;
		align-items: center;
		color: white;
		font-size: 12px;
		background-color: #0e2442;

		.header-logo-img {
			height: 60px;
			width: 60px;
		}
	}

	.login-user {
		color: #737373;
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.lang {
		cursor: pointer;
		text-align: center;
		width: 30px;
		height: 30px;
		line-height: 30px;
		border: 1px solid gray;
		margin-right: 20px;
	}
</style>