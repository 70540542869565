export default {
	/*-----------------------------菜單類(開始)-----------------------------*/
	// 儀錶盤
	Dashboard: 'Dashboard',
	/*-----------------------------菜單類(結束)-----------------------------*/
	
	/*-----------------------------操作按鈕類(開始)-----------------------------*/
	Search: 'Search',
	Send: 'Send',
	Reset: 'Reset',
	Add: 'Add',
	Delete: 'Detele',
	Edit: 'Edit',
	Refresh: 'Refresh',
	New: 'New',
	Export: 'Export',
	Print: 'Print',
	Detail:'Detail',
	EditPrjCategory:'Project Category',
	AttendSetting: 'Attendance Setting',
	/*-----------------------------操作按鈕類(結束)-----------------------------*/


	/*-----------------------------表（開始）-----------------------------*/
	Company:'Company',
	Order_Number:'Order Number',
	Please_enter:'Please enter',
	Please_select:'Please select',
	Creator:'Creator',
	Project:'Project',
	Project_name:'Project Name',
	Customer:'Customer',
	State:'State',
	All:'All',
	Initialization:'Initialization',
	Pending_payment:'Pending Payment',
	Completed:'Completed',
	Close:'Close',
	Serial_Number:'No',
	Creation_Time:'Creation Time',
	Total_Amount:'Total Amount',
	Remark:'Remark',
	Operation:'Operation',
	Send_the_quotation:'Send receipts',
	Send_Bill:'Send invoices',
	Cancel:'Cancel',
	Save:'Save',
	Receipt_Number:'Quote Number',
	Invoice_number:'Bill Number',
	Amounts:'Amounts',
	Addressee:'Addressee',
	Mailbox:'Mailbox',
	Address:'Address',
	The_Handler:'The Handler',
	Undertaker:'Undertaker',
	Sent_successfully:'Sent successfully',
	Added_successfully:'Added successfully',
	Saved_successfully:'Saved successfully',
	Delete_successfully:'Delete successfully',
	Remove_Warning:'Once deleted, it cannot be restored. Do you want to continue? ',
	Warning:'Warning',
	confirm:'Confirm',
	Name:'Name',
	Description:'Description',
	The_updated_person:'The updated person',
	Update_Time:'Update Time',
	File:'File',
	Picture:'Picture',
	Extract_Files:'Extract Files',
	Please_add_the_description:'Please add the description',
	Project_Classification:'Project Classification',
	Under_Way:'Under Way',
	Start_date:'Start Date',
	End_date:'End Date',
	Quantity_of_work:'Quantity of work',
	Evaluate:'Evaluate',
	Department:'Department',
	Attendance_object:'Attendance Object',
	Attendance_Date:'Attendance Date',
	to:'To',
	Normal:'Enabled',
	Late:'Late',
	Leave_early:'Leave Early',
	Absence:'Absence',
	Working_time:'Working Time',
	Offduty_time:'Off-duty Time',
	Working_hours:'Working Hours',
	Apply:'Apply',
	Nickname:'Nickname',
	Pay_Day:'Pay Day',
	Object_of_payment:'Object of payment',
	Basic_pay:'Basic Pay',
	Bonus_allowance:'Bonus/Allowance',
	deduction:'Deduction',
	net_payroll:'Net Payroll',
	Date_of_payment:'Date of payment',
	Employee:'Employee',
	Holiday:'Holiday',
	applicant:'Applicant',
	Leave_instructions:'Leave Instructions',
	Application_time:'Application Time',
	Type_of_leave:'Type',
	Annual_leave:'Annual Leave',
	Sick_leave:'Sick Leave',
	Unpaid_leave:'Unpaid Leave',
	Applied:'Applied',
	Passed:'Passed',
	Rejected:'Rejected',
	Vacationers:'Vacationers',
	attachment:'attachment ',
	No_attachment:'No attachment',
	Number_of_Customers:'Number of Customers',
	Unit_pcs:'Unit: pcs',
	Unit_pcsQ:'Unit: HDK',
	Order_Income:'Order Income',
	Order_Quantity:'Order Quantity',
	title:'Title',
	disable:'Disabled',
	No_data:'No data',
	submit:'submit',
	content:'Content',
	unknown:'unknown',
	months:'Months',
	Export_Failed:'Export Failed',
	Timeframe:'Timeframe',
	Basic_Wage:'Basic Wage',
	Uploader:'Uploader',
	Upload_Time:'Upload Time',
	take_effect:'Take Effect',
	Choose_a_file:'Choose a file',
	Please_uploaded:'Please select the files to be uploaded !',
	Company_Name:'Company Name',
	Full_company_name:'Full company name',
	Company_Profile:'Company Profile',
	Contact_Information:'Contact Information',
	Company_Address:'Company Address',
	Registration_No:'Registration No.',
	Business_License:'Business License',
	Customer_Name:'Customer Name',
	Contact:'Contacter',
	The_contact_phone:'Contact Phone',
	Department_Name:'Department Name',
	Subordinate_Departments:'Subordinate Departments',
	Role_Name:'Role Name',
	Role_Coding:'Role Coding',
	Allocation_of_authority:'Allocation of authority',
	Frozen:'Disabled',
	Account_Number:'Username',
	gender:'Gender',
	man:'Male',
	woman:'Female',
	birthday:'Birthday',
	Cell_Phone_Number:'Cell Phone Number',
	identity_card:'Identity Card',
	Assignment_of_roles:'Assignment of roles',
	Reset_Password:'Reset Password',
	Password:'Password',
	Phone:'Phone',
	New_Password:'New Password',
	Reset_Successful:'Reset Successful',
	Logout:'Logout',
	LoginOutWarning:'Are you sure you want to log out of your current account?',
	prompt:'prompt',
	The_customer_exists:'The customer no longer exists',
	Company_Information:'Company Information',
	Company_Slogan:'Company Slogan',
	The_role_code_already_exists:'The role code already exists'











	/*-----------------------------表（結束）-----------------------------*/


}