<template>
	<div class="dashboard-content">
		<div class="dashboard-left">
			<div id="dashboard-client"></div>
			<div id="dashboard-revenue"></div>
		</div>
		<div class="dashboard-right">
			<div id="dashboard-order"></div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import {
		getClientNum,
		getOrderNum,
		getOrderRevenue,
	} from '@/http/dashboard'
	import i18n from '@/lang'

	export default {
		data() {
			return {
				chart: '',
				myChart1: null,
				myChart2: null,
				myChart3: null,
			}
		},
		methods: {
			initClient() {
				getClientNum().then(res => {
					if (res.code == 200) {
						let keys = [];
						let vals = [];
						let list = res.data;
						for (let i = 0; i < list.length; i++) {
							keys.push(list[i].yearMonth);
							vals.push(list[i].num);
						}
						this.myChart1.setOption({
							title: {
								text: i18n.tc('Number_of_Customers'),
								left: 'center',
								subtext: this.$i18n.tc('Unit_pcs')
							},
							xAxis: {
								data: keys
							},
							yAxis: {},
							series: [{
								type: 'bar',
								data: vals,
								label: {
									show: true,
									position: 'top'
								}
							}]
						});
					}
				})
			},
			initOrder() {
				getOrderNum().then(res => {
					if (res.code == 200) {
						let keys = [];
						let vals = [];
						let list = res.data;
						for (let i = 0; i < list.length; i++) {
							keys.push(list[i].yearMonth);
							vals.push(list[i].num);
						}

						this.myChart2.setOption({
							title: {
								text: this.$i18n.tc('Order_Quantity'),
								left: 'center',
								subtext: this.$i18n.tc('Unit_pcs')
							},
							xAxis: {
								data: keys
							},
							yAxis: {},
							series: [{
								type: 'bar',
								data: vals,
								label: {
									show: true,
									position: 'top'
								}
							}]
						});
					}
				});
			},
			initRevenue() {
				getOrderRevenue().then(res => {
					if (res.code == 200) {
						let keys = [];
						let vals = [];
						let list = res.data;
						for (let i = 0; i < list.length; i++) {
							keys.push(list[i].yearMonth);
							vals.push(Number(list[i].money).toFixed(2));
						}
						this.myChart3.setOption({
							title: {
								text: this.$i18n.tc('Order_Income'),
								left: 'center',
								subtext: this.$i18n.tc('Unit_pcsQ')
							},
							xAxis: {
								type: 'category',
								data: keys
							},
							yAxis: {
								type: 'value',
							},
							series: [{
								data: vals,
								type: 'line',
								label: {
									show: true,
									position: 'top',
									axisLabel: {
									formatter: function(value){
										return value = value/1000+'k';
									}
								}
								},
								
							}]
						});
					}
				});
			},
			resize(){
				this.myChart1.resize()
				this.myChart2.resize()
				this.myChart3.resize()
			}
		},
		created() {
			let token = localStorage.getItem('token');
			if (!token) {
				this.$router.push('/login')
			}
		},
		mounted(){
			window.addEventListener("resize", this.resize);
			this.myChart1 = echarts.init(document.getElementById('dashboard-client'));
			this.myChart2 = echarts.init(document.getElementById('dashboard-revenue'));
			this.myChart3 = echarts.init(document.getElementById('dashboard-order'));
			this.initClient();
			this.initOrder();
			this.initRevenue();
		},
		watch:{
			'$i18n.locale'(){
				this.initClient();
				this.initOrder();
				this.initRevenue();

			}
		},
	};
</script>

<style lang="less" scoped>
	.dashboard-content {
		width: 100%;
		height: 100%;
		display: flex;

		.dashboard-left {
			width: 40%;
			height: 100%;

			#dashboard-client {
				width: 100%;
				height: 50%;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-bottom: none;
				border-right: none;
				box-sizing: border-box;
			}

			#dashboard-revenue {
				width: 100%;
				height: 50%;
				border: 1px solid rgba(0, 0, 0, 0.1);
				border-right: none;
				box-sizing: border-box;
			}
		}

		.dashboard-right {
			width: 60%;
			height: 100%;
			#dashboard-order {
				width: 100%;
				height: 100%;
				border: 1px solid rgba(0, 0, 0, 0.1);
				box-sizing: border-box;
				canvas{
					position: absolute !important;
					left: 15px !important;
					height: 80% !important;
				}
			}
		}
	}
</style>