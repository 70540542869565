export default {
	/*-----------------------------菜單類(開始)-----------------------------*/
	// 儀錶盤
	Dashboard: '儀錶盤',
	/*-----------------------------菜單類(結束)-----------------------------*/
	
	/*-----------------------------操作按鈕類(開始)-----------------------------*/
	Search: '查詢',
	Send: '發送',
	Reset: '重置',
	Add: '添加',
	Delete: '刪除',
	Edit: '編輯',
	Refresh: '刷新',
	New: '新增',
	Export: '導出',
	Print: '打印',
	Detail:'明細',
	EditPrjCategory:'分類管理',
	AttendSetting: '考勤設置',
	/*-----------------------------操作按鈕類(結束)-----------------------------*/

	/*-----------------------------表（開始）-----------------------------*/
	Company:'公司',
	Order_Number:'訂單號',
	Please_enter:'請輸入',
	Please_select:'請選擇',
	Creator:'創建人',
	Project:'項目',
	Project_name:'項目名稱',
	Customer:'客戶',
	State:'狀態',
	All:'全部',
	Initialization:'初始化',
	Pending_payment:'待付款',
	Completed:'已完成',
	Close:'關閉',
	Serial_Number:'序號',
	Creation_Time:'創建時間',
	Total_Amount:'總金額',
	Remark:'備注',
	Operation:'操作',
	Send_the_quotation:'發送報價',
	Send_Bill:'發送賬單',
	Cancel:'取消',
	Save:'保存',
	Receipt_Number:'報價單號',
	Invoice_number:'賬單號',
	Amounts:'金額',
	Addressee:'收件人',
	Mailbox:'郵箱',
	Address:'地址',
	The_Handler:'經手人',
	Undertaker:'承接人',
	Sent_successfully:'發送成功',
	Added_successfully:'添加成功',
	Saved_successfully:'保存成功',
	Delete_successfully:'刪除成功',
	Reset_Successful:'重置成功',
	Remove_Warning:'一經刪除無法恢復，是否繼續',
	Warning:'警告',
	confirm:'確認',
	Name:'名稱',
	Description:'描述',
	The_updated_person:'更新人',
	Update_Time:'更新時間',
	File:'文件',
	Picture:'圖片',
	Extract_Files:'選取文件',
	Please_add_the_description:'請添加描述',
	Project_Classification:'項目分類',
	Under_Way:'進行中',
	Start_date:'開始日期',
	End_date:'結束日期',
	Quantity_of_work:'工程量',
	Evaluate:'估價',
	Department:'部門',
	Attendance_object:'考勤對象',
	Attendance_Date:'考勤日期',
	to:'至',
	Normal:'正常',
	Late:'遲到',
	Leave_early:'早退',
	Absence:'缺勤',
	Working_time:'上班時間',
	Offduty_time:'下班時間',
	Working_hours:'工作時長',
	Apply:'應用',
	Nickname:'昵稱',
	Pay_Day:'發薪日',
	Object_of_payment:'發放對象',
	Basic_pay:'基礎工資',
	Bonus_allowance:'獎金/津貼',
	deduction:'扣除項',
	net_payroll:'實發工資',
	Date_of_payment:'發放日期',
	Employee:'員工',
	Holiday:'休假日',
	applicant:'申請人',
	Leave_instructions:'休假説明',
	Application_time:'申請時間',
	Type_of_leave:'休假類型',
	Annual_leave:'年假',
	Sick_leave:'病假',
	Unpaid_leave:'無薪假',
	Applied:'已申請',
	Passed:'已通過',
	Rejected:'已拒絕',
	Vacationers:'休假員工',
	attachment:'附件',
	No_attachment:'沒有附件',
	Number_of_Customers:'客戶數量',
	Unit_pcs:'單位: 個',
	Unit_pcsQ:'單位: 港幣',
	Order_Income:'訂單收入',
	Order_Quantity:'訂單數量',
	title:'標題',
	disable:'禁用',
	No_data:'沒有數據',
	submit:'提交',
	content:'内容',
	unknown:'未知',
	months:'月份',
	Export_Failed:'導出失敗',
	Timeframe:'時間範圍',
	Basic_Wage:'基本工資',
	Uploader:'上傳人',
	Upload_Time:'上傳時間',
	take_effect:'生效',
	Choose_a_file:'選取文件',
	Please_uploaded:'請選擇要上傳的文件！',
	Company_Name:'公司名稱',
	Full_company_name:'公司全名',
	Company_Profile:'公司簡介',
	Company_Information:'公司信息',
	Contact_Information:'聯係方式',
	Company_Address:'公司地址',
	Registration_No:'注冊號',
	Business_License:'營業執照',
	Customer_Name:'客戶名稱',
	Contact:'聯係人',
	The_contact_phone:'聯係人電話',
	Department_Name:'部門名稱',
	Subordinate_Departments:'上級部門',
	Role_Name:'角色名稱',
	Role_Coding:'角色編碼',
	Allocation_of_authority:'權限分配',
	Frozen:'凍結',
	Account_Number:'賬號',
	gender:'性別',
	man:'男',
	woman:'女',
	birthday:'生日',
	Cell_Phone_Number:'手機號碼',
	identity_card:'身份證',
	Assignment_of_roles:'分配角色',
	Reset_Password:'重置密碼',
	Password:'密碼',
	Phone:'手機',
	New_Password:'新密碼',
	Logout:'退出登錄',
	LoginOutWarning:'確定要登出當前賬號嗎?',
	prompt:'提示',
	The_customer_exists:'客戶已不存在',
	Company_Slogan:'公司口號',
	The_role_code_already_exists:'角色編碼已存在'




	/*-----------------------------表（結束）-----------------------------*/
}