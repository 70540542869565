import axios from 'axios';
import {
	Message,
	Loading,
} from 'element-ui';

let loading;
//内存中正在请求的数量
let loadingNum = 0;

// 开始加载
function startLoading() {
	if (loadingNum == 0) {
		loading = Loading.service({
			lock: true,
			text: '拼命加载中...',
			background: 'rgba(255,255,255,0.5)',
		})
	}
	//请求数量加1
	loadingNum++;
}

// 结束加载
function endLoading() {
	//请求数量减1
	loadingNum--
	if (loadingNum <= 0) {
		loading.close()
	}
}

// 请求前缀
axios.defaults.baseURL = process.env.VUE_APP_BASEURL_API;

// 请求超时时间
axios.defaults.timeout = 60000;

// post请求头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 请求拦截器
axios.interceptors.request.use(
	config => {
		startLoading();
		// 请求携带token
		const token = localStorage.getItem('token');
		token && (config.headers.token = token);
		return config;
	},
	error => {
		return Promise.error(error);
	})

// 响应拦截器
axios.interceptors.response.use(
	// 2xx 范围内的状态码都会触发该函数。
	response => {
		endLoading();
		switch (response.data.code) {
			case 200:
				return response;
				break;
			case 401:
				// token无效或已过期
				Message.error(response.data.msg + ' 請重新登錄');
				// 移除本地缓存
				localStorage.removeItem('token');
				localStorage.removeItem('user');

				setTimeout(function() {
					// 重新跳到登錄頁
					let url = location.href;
					const startIndex = url.indexOf('//') + 2; // 找到双斜杠后的位置
					const endIndex = url.indexOf('/', startIndex); // 找到第一个斜杠的位置
					url = url.substring(0, endIndex);
					location.href = url + '/login';

					return response;
				}, 2000);
				break;
			default:
				console.error(response);
				// Message.error(response.data.msg || '请求异常');
				return response;
				break;
		}
	},
	// 超出 2xx 范围的状态码都会触发该函数。
	error => {
		endLoading();
		const response = error.response;
		if (response.data.code) {
			console.error(response);
			Message.error(response.data.msg || '请求异常');
			return response;
		} else {
			Message.error('服务器异常，请稍后再试！');
			return response;
		}
	}
);
/** 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios.get(url, params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
/** 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
	return new Promise((resolve, reject) => {
		axios.post(url, params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
/** 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function put(url, params) {
	return new Promise((resolve, reject) => {
		axios.put(url, params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
/** 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function del(url, params) {
	return new Promise((resolve, reject) => {
		axios.delete(url, params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
/** 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function options(url, params) {
	return new Promise((resolve, reject) => {
		axios.options(url, params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
/** 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function head(url, params) {
	return new Promise((resolve, reject) => {
		axios.head(url, params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}
/** 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function patch(url, params) {
	return new Promise((resolve, reject) => {
		axios.patch(url, params)
			.then(res => {
				resolve(res.data);
			})
			.catch(err => {
				reject(err.data)
			})
	});
}